@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-BoldItalic.eot');
  src: local('Basier Circle Bold Italic'), local('BasierCircle-BoldItalic'),
  url('../assets/fonts/BasierCircle-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-BoldItalic.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-BoldItalic.woff') format('woff'),
  url('../assets/fonts/BasierCircle-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-MediumItalic.eot');
  src: local('Basier Circle Medium Italic'), local('BasierCircle-MediumItalic'),
  url('../assets/fonts/BasierCircle-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-MediumItalic.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-MediumItalic.woff') format('woff'),
  url('../assets/fonts/BasierCircle-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-RegularItalic.eot');
  src: local('Basier Circle Regular Italic'), local('BasierCircle-RegularItalic'),
  url('../assets/fonts/BasierCircle-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-RegularItalic.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-RegularItalic.woff') format('woff'),
  url('../assets/fonts/BasierCircle-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-Medium.eot');
  src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
  url('../assets/fonts/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-Medium.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-Medium.woff') format('woff'),
  url('../assets/fonts/BasierCircle-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-Bold.eot');
  src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
  url('../assets/fonts/BasierCircle-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-Bold.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-Bold.woff') format('woff'),
  url('../assets/fonts/BasierCircle-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-SemiBoldItalic.eot');
  src: local('Basier Circle SemiBold Italic'), local('BasierCircle-SemiBoldItalic'),
  url('../assets/fonts/BasierCircle-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-SemiBoldItalic.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-SemiBoldItalic.woff') format('woff'),
  url('../assets/fonts/BasierCircle-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-SemiBold.eot');
  src: local('Basier Circle SemiBold'), local('BasierCircle-SemiBold'),
  url('../assets/fonts/BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-SemiBold.woff') format('woff'),
  url('../assets/fonts/BasierCircle-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../assets/fonts/BasierCircle-Regular.eot');
  src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
  url('../assets/fonts/BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BasierCircle-Regular.woff2') format('woff2'),
  url('../assets/fonts/BasierCircle-Regular.woff') format('woff'),
  url('../assets/fonts/BasierCircle-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Basier Circle', sans-serif !important;
}
