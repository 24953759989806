@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./fonts.css);
@import url(./colors.css);

.ant-message,
.ant-notification,
.ant-tabs-dropdown {
  z-index: 9999999 !important;
}

.vgs-field-container,
.vgs-field-container iframe {
  margin: 0px !important;
  height: 100% !important;
  max-height: 80px !important;
  width: 100% !important;
}

.vgs-copy-container,
.vgs-copy-container iframe {
  margin: 0px !important;
  max-height: 80px !important;
  height: 24px !important;
  width: 24px !important;
  z-index: 9999 !important;
  cursor: pointer;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
