
:root {
  --black: #000000;
  --dark-black: #122C34;
  --red: #ED0E61;
  --white: #FFFFFF;
  --grey: #748094;
  --button-grey-bg: #F1F2F5;
  --grey100: #F6F8FA;
  --pagination-border: #E2E3E6;
  --mystic: #E3E8EF;
  --positive-green: #E3F6ED;
  --green: #34a853;
  --green-dark: #51A351;
  --green-light: #E0F6E0;
  --negative-red: #FAE9E8;
  --light-grey: rgba(227, 232, 239, 0.2);
  --light-grey-plus: #858585;
  --lighter-grey: #B4B3C2;
  --tertiary: #9EA6B2;
  --yellow-dark: #876800;
  --yellow: #fff1b8;
  --blue-dark: #0050b3;
  --lighter-blue: #EAF5FF;
  --light-blue: #32A1FF;
  --blue: #91d5ff;
  --dark-blue: #211C5C;
  --dark-blue-plus: #161D2E;
  --hsa-bg: var(--dark-blue);
  --benepass-bg: var(--white);
  --card-border: #BBBBBB;
  --dark: var(--dark-blue);
  --dark-red: #DF3333;
  --light-red: #FAE4E4;
  --dark-grey: #4A5568;
  --grey-light: #EFEFEF;
  --main: var(--dark-blue);
  --grayish-blue: #5E5B84;
  --dark-black: #161D2E;
  --sidebarItemColor: var(--light-blue);
  --title: var(--dark-blue);
  --subtitle: var(--grey);
  --tabColorActive: var(--dark-blue);
  --tabColorInactive: var(--grey);
  --tag-card-error: var(--dark-red);
  --tag-card-inactive: var(--grey);
  --tag-card-active: var(--green);
  --button-bg: var(--light-blue);
  --button-color: var(--white);
  --button-primary-bg: var(--light-blue);
  --button-primary-color: var(--white);
  --button-warning-bg: var(--dark-red);
  --error: var(--dark-red);
  --individual: #F2C94C;
  --orange-light: #FAEEE4;
  --orange: #EE9E27;
  --family: #F2994A;
  --tag-discount-bg: #ececec;
  --purple: #453F93;
  --purple-light: #F0EFFE;
  --education-bg: #E6E6E9;
  --theme-color: var(--dark-blue);
}
